import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { graphql } from "gatsby";
import Fade from "react-reveal/Fade";
import Img from "gatsby-image";
import Layout from "../../../components/Common/Layout";
import CompanyLogo from "../../../components/Website/TrustUs/CompanyLogo";
import ProjectsNavigation from "../../../components/Website/ProjectsNavigation";
import ServicePellet from "../../../components/Website/ServicePellet";
import iberdrolaVideo from "../../../../static/img/iberdrola.mp4";

const Iberdrola = ({ data }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const imagesData = data.allFile.edges.map(
    (edge) => edge.node.childImageSharp.fluid
  );

  return (
    <Layout
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      selectedLanguage="pt"
      title="Brocode Studio || Árvore da Felicidade"
      description="A Iberdrola queria uma campanha dinâmica na web que
      envolvesse sua base de clientes."
    >
      <div className="project-page">
        <div className="brocode-content">
          <Row className="bk-about-area">
            <Col md={5} xs={12}>
              <Fade bottom duration={2000}>
                {" "}
                <div className="content">
                  <div className="title wow move-up mb--10">
                    <h2>Árvore da Felicidade</h2>
                  </div>
                  <ServicePellet service="Development" />
                  <p className="wow move-up mb--20">
                    A Iberdrola queria uma campanha dinâmica na web que
                    envolvesse sua base de clientes.
                  </p>
                  <p className="wow move-up mb--20">
                    Árvore da Felicidade é uma experiência web habilitada para
                    Aprendizado de Máquina com reconhecimento de emoção que
                    desencadeia pistas visuais e mudanças de cenário.
                  </p>
                </div>
              </Fade>
            </Col>
            <Col md={7} xs={12}>
              <Img fluid={imagesData[3]} />
            </Col>
          </Row>
          <Fade bottom duration={2000}>
            {" "}
            <Row className="my-4">
              <Col xs={12}>
                <video
                  id="iberdrola-video"
                  className="w-100 mt_md--40 mt_sm--40  move-up"
                  src={iberdrolaVideo}
                  loop
                  autoPlay
                  muted
                />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            {" "}
            <Row className="my-4">
              <Col md={6} xs={12}>
                {" "}
                <Img fluid={imagesData[1]} />
              </Col>
              <Col md={6} xs={12}>
                {" "}
                <Img fluid={imagesData[2]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            {" "}
            <Row className="my-4">
              <Col xs={12}>
                <Img fluid={imagesData[0]} />
                <p className="my-4">Initial Desktop Screen</p>
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="justify-content-center">
              <CompanyLogo name="iberdrola" />
            </Row>
          </Fade>

          <ProjectsNavigation
            nextProject={{
              name: "Gio’s Masterclass",
              link: "/work/giomasterclass",
            }}
            prevProject={{ name: "Liberpater", link: "/work/liberpater" }}
            selectedLanguage="pt"
          />
        </div>
      </div>
    </Layout>
  );
};

export default Iberdrola;

export const IberdrolaQuery = graphql`
  query IberdrolaPhotosPt {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)|(mp4)/" }
        dir: { regex: "/static/img/projects/iberdrola/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
